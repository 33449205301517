import { Message } from "element-ui";
import store from "@/store/index";
import apiIm from "@/api/apiIminfo";
var SDK;
if (process.client) {
  // import SDK from "@/static/nimSDK/NIM_Web_SDK_v7.0.0.js";
  SDK = require("@/static/nimSDK/NIM_Web_SDK_v7.0.0.js");
}
function onConnect() {
  if (process.client) {
    store().commit("updateNimState", true);
    window.nim && window.nim.getClientAntispamLexicon({
      done: function(error) {
        if (!error) {
        }
      }
    });
  }
}

function onDisconnect(error) {
  console.log(error);
  if (error) {
    store().commit("updateNimState", false);
    switch (error.code) {
      // 账号或者密码错误
      case 302:
        Message.error("账号或者密码错误");
        break;
      // 重复登录, 已经在其它端登录了
      case 417:
        Message.error("重复登录, 已经在其它端登录了");
        break;
      // 被踢, 请提示错误后跳转到登录页面
      case "kicked":
        var map = {
          PC: "电脑版",
          Web: "网页版",
          Android: "手机版",
          iOS: "手机版",
          Mac: "电脑版",
          WindowsPhone: "手机版"
        };
        var str = error.from;
        Message.error(
          "你的帐号被" +
            (map[str] || "其他端") +
            "踢出下线，请确定帐号信息安全!"
        );
        break;
      default:
        break;
    }
  }
}
function onWillReconnect(obj) {
  // 此时说明 SDK 已经断开连接, 请开发者在界面上提示用户连接已断开, 而且正在重新建立连接
  // this.loadingText = "账号连接已断开, 正在重新建立连接.....";
  // this.loading = true;
}
function onError(error) {}
function onLoginPortsChange(loginPorts) {}
//漫游消息
function onRoamingMsgs(obj) {
  pushMsg(obj.msgs);
}
// 离线消息
function onOfflineMsgs(obj) {
  pushMsg(obj.msgs);
}
// 收到消息
function onMsg(msg) {
  pushMsg(msg);
}
//增加消息聊天
function pushMsg(msgs) {
  store().commit("SET_MSGS", msgs);
}
//收到离线系统通知
function onOfflineSysMsgs(sysMsgs) {
  pushSysMsgs(sysMsgs);
}
//漫游系统消息
function onroamingsysmsgs(sysMsgs) {
  pushSysMsgs(sysMsgs);
}
//收到系统通知
function onSysMsg(sysMsg) {
  pushSysMsgs(sysMsg);
}
//更新系统消息
function onUpdateSysMsg(sysMsg) {
  pushSysMsgs(sysMsg);
}
async function onSessions(session) {
  console.log("会话列表", session);
  let list = await getUserName(session);
  store().commit("SET_SESSION", list);
}
//会话更新
async function onUpdateSession(session) {
  let list = await getUserName(session);
  store().commit("UPDATE_SESSION", list[0]);
}
//增加系统消息
function pushSysMsgs(sysMsgs) {
  if (process.client) {
    let sysMsgList = Array.isArray(sysMsgs) ? sysMsgs : [sysMsgs];
    if (sysMsgList.length > 0) {
      sysMsgList.forEach((item, index) => {
        if (item.type == "passFriendApply" && !item.read) {
          // store().dispatch('setFriends',item.friend)
          window.nim.markSysMsgRead({
            sysMsgs: [item],
            done: (err, obj) => {
              console.log(err, obj);
            }
          });
        } else if (item.type == "deleteFriend" && !item.read) {
          store().commit("DELETE_FRIEND", item.from);
          window.nim.markSysMsgRead({
            sysMsgs: [item],
            done: (err, obj) => {
              console.log(err, obj);
            }
          });
        }
      });
    }
    store().commit("SET_SYSMSGS", sysMsgs);
  }
}
// 收到系统通知未读数
function onSysMsgUnread(obj) {
  store().commit("updatwSysMsgUnread", obj);
}
// 系统通知未读数更新了
function onUpdateSysMsgUnread(obj) {
  store().commit("updatwSysMsgUnread", obj);
}
// 收到离线自定义系统通知
function onOfflineCustomSysMsgs(sysMsgs) {
  store().commit("updatwCustomSysMsgs", sysMsgs);
}
// 收到自定义系统通知
function onCustomSysMsg(sysMsg) {
  store().commit("updatwCustomSysMsgs", sysMsg);
}
//同步完成
function onSyncDone() {
  if (process.client) {
    window.nim.getLocalSysMsgs({
      limit: 100,
      done: (err, obj) => {
        if (!err) {
          store().commit("SET_SYSMSGS", obj.sysMsgs);
        }
      }
    });
  }
}
var initNim = (account, token) => {
  if (process.client) {
    if (window.nim) return;
    window.nim = SDK.NIM.getInstance({
      db: true,
      appKey: "06797c7320a41940acfa4f640de73559",
      account: account, // "201910091558292"
      token: token, // "bfe073d0a5fb96efa91e1a40c87217ce"
      syncSessionUnread: true,
      onconnect: onConnect,
      ondisconnect: onDisconnect,
      onwillreconnect: onWillReconnect,
      onerror: onError,
      onloginportschange: onLoginPortsChange,
      autoMarkRead: false,
      onsessions: onSessions,
      onupdatesession: onUpdateSession,
      // 消息
      onroamingmsgs: onRoamingMsgs,
      onofflinemsgs: onOfflineMsgs,
      onmsg: onMsg,
      // 好友关系
      onfriends: onFriends,
      onsyncfriendaction: onSyncFriendAction,
      // 用户名片
      // onmyinfo: nimTool.onMyInfo,
      // onupdatemyinfo: nimTool.onUpdateMyInfo,
      // onusers: nimTool.onUsers,
      // onupdateuser: nimTool.onUpdateUser,
      // 群组
      onteams: onTeams,
      onsynccreateteam: onCreateTeam,
      onUpdateTeam: onUpdateTeam,
      onCreateTeam: onCreateTeam,
      onDismissTeam: onDismissTeam,
      onAddTeamMembers: onAddTeamMembers,
      onRemoveTeamMembers: onRemoveTeamMembers,
      // 系统通知
      syncRoamingMsgs: true,
      onofflinesysmsgs: onOfflineSysMsgs,
      onroamingsysmsgs: onroamingsysmsgs,
      onsysmsg: onSysMsg,
      onupdatesysmsg: onUpdateSysMsg,
      onsysmsgunread: onSysMsgUnread,
      onupdatesysmsgunread: onUpdateSysMsgUnread,
      onofflinecustomsysmsgs: onOfflineCustomSysMsgs,
      oncustomsysmsg: onCustomSysMsg,
      // 同步完成
      onsyncdone: onSyncDone
    });
  }
};
export default initNim;
//发消息
export function sendTipMsg(msg, state) {
  if (process.client) {
    let myUser = state.user.imAccount;
    window.nim.sendTipMsg({
      isLocal: true,
      scene: msg.scene,
      to: msg.target,
      tip: (msg.from !== myUser ? "对方" : "你") + "撤回了一条消息",
      time: msg.time,
      done: (err, data) => {
        if (!err) {
          store().commit("SET_MSGS", data);
        }
      }
    });
  }
}

//群列表
function onTeams(teams) {
  store().commit("SET_TEAMS", teams);
  store().dispatch("getTeamMembers", teams);
}

//群创建后的回调
function onCreateTeam(team) {
  store().commit("SET_TEAMS", team);
  store().dispatch("getTeamMembers", team);
}

function onDismissTeam(obj) {
  store().commit("DELETE_TEAM", {
    invalid: { teamId: obj.teamId }
  });
}

// 群状态更新
function onUpdateTeam(team) {
  store().commit("SET_TEAMS", team);
}

//好友列表
// async function onFriends(friends) {
//   // store().dispatch("setFriends", friends)
// }
function onFriends(friends) {
  console.log('收到好友列表', friends);
  store.dispatch("setFriends", friends)
}

//在其他端对好友的操作
function onSyncFriendAction(obj) {
  switch (obj.type) {
    case "addFriend":
      console.log(
        "你在其它端直接加了一个好友" + obj.account + ", 附言" + obj.ps
      );
      // store().dispatch("setFriends", obj.friend)
      break;
    case "applyFriend":
      console.log(
        "你在其它端申请加了一个好友" + obj.account + ", 附言" + obj.ps
      );
      break;
    case "passFriendApply":
      console.log(
        "你在其它端通过了一个好友申请" + obj.account + ", 附言" + obj.ps
      );
      // store().dispatch("setFriends", obj.friend)
      break;
    case "rejectFriendApply":
      console.log(
        "你在其它端拒绝了一个好友申请" + obj.account + ", 附言" + obj.ps
      );
      break;
    case "deleteFriend":
      console.log("你在其它端删了一个好友" + obj.account);
      store().dispatch("DELETE_FRIEND", obj.account);
      break;
    case "updateFriend":
      console.log("你在其它端更新了一个好友", obj.friend);
      // store().dispatch("setFriends", obj.friend)
      break;
  }
}

// 群组成员发生变化
function onAddTeamMembers(obj) {
  store().commit("SET_TEAMS", obj.team);
  store().dispatch("getTeamMembers", obj.team);
}
function onRemoveTeamMembers(obj) {
  store().commit("SET_TEAMS", obj.team);
  store().dispatch("getTeamMembers", obj.team);
}

//用户信息
function onMyInfo(user) {
  console.log("收到我的名片", user);
  // data.myInfo = user;
}
function onUpdateMyInfo(user) {
  console.log("我的名片更新了", user);
  // data.myInfo = NIM.util.merge(data.myInfo, user);
}
function onUsers(users) {
  console.log("收到用户名片列表", users);
  // data.users = nim.mergeUsers(data.users, users);
}
function onUpdateUser(user) {
  console.log("用户名片更新了", user);
  // data.users = nim.mergeUsers(data.users, user);
}

async function getUserName(session) {
  let list = [];
  if (!Array.isArray(session)) {
    list = [session];
  } else {
    list = session;
  }
  let ids = [];
  let myAccount = store().state.user.imAccount;
  let teamList = store().state.teams || [];
  list.forEach(item => {
    let res = store().state.session.filter(info => info.to == item.to);
    if (res.length > 0) {
      item.name = res[0].name;
    } else if (item.scene == "p2p" && item.to != "systemnoti") {
      ids.push(item.to);
    } else if (item.scene == "team") {
      let isHas = teamList.filter(item => item.teamId == item.to);
      if (isHas.length == 0) {
        nim.getTeamMemberByTeamIdAndAccount({
          teamId: item.to,
          account: myAccount,
          done: (err, obj) => {
            if (err) {
              deleteTeam(item.to);
            }
          }
        });
      }
    }
  });
  if (ids.length > 0) {
    let res = await apiIm.getUserName({ accountList: ids });
    if (res.data.code == 200) {
      list.forEach(item => {
        if (!item.name && item.scene == "p2p" && item.to != "systemnoti") {
          let name = res.data.data.filter(info => {
            return info.imAccount == item.to;
          });
          item.name =
            name.length == 0
              ? ""
              : name[0].realName || name[0].name || name[0].userName;
          // item.name = name[0].realName || name[0].name || name[0].userName
        }
      });
    }
  }
  return list;
}
function deleteTeam(to) {
  if (process.client) {
    //删除消息会话
    window.nim.deleteServerSessions({
      sessions: [
        {
          scene: "team",
          to: to
        }
      ],
      done: (err, obj) => {}
    });
    window.nim.deleteLocalSession({
      id: "team-" + to,
      done: (err, obj) => {}
    });
    store().commit("DELETE_SESSION", "team-" + to);
  }
}
export async function getFriendName(friends){
  let list = []
  if(!Array.isArray(friends)){
    list = [friends]
  }else{
    list = friends
  }
  let ids = []
  list.forEach(item=>{
    let res = store.state.friends.filter(info=>(info.account == item.account))
    if(res.length>0){
      item.name = res[0].name
    }else{
      ids.push(item.account)
    }
  })
  if(ids.length > 0){
    let res = await apiIm.getUserName({accountList: ids,type: 'friends'})
    list.forEach(item=>{
      if(!item.name && res.data.code == 200 && res.data.data){
        let name = res.data.data.filter(info=>{
          return info.imAccount == item.account
        })
        item.name = name.length > 0 ? name[0].realName || name[0].name || name[0].userName : ''
        item.telPhone = name.length > 0 ? name[0].telPhone : ''
      }
    })
  }
  return list
}
