export const heavyArr = (arr, fied) => {
  let hash = {}; //去重
  let result = arr.reduce((preVal, curVal) => {
    hash[curVal.fied] ? "" : (hash[curVal.fied] = true && preVal.push(curVal));
    return preVal;
  }, []);
  return result;
};
// oss图片剪切
export const imageCutting = (url, width, height, mode = "fill") => {
  if (!url) return url;
  let cutUrl = "";
  if (url) {
    const index = url && url.indexOf("?");
    cutUrl = index !== -1 ? url.substr(0, index) : url;
  }
  let process = "x-oss-process";
  if (cutUrl.includes(".myhuaweicloud.com")) {
    process = "x-image-process";
  }
  return cutUrl
    ? `${cutUrl}?${process}=image/resize,m_${mode},w_${width},h_${height},limit_0`
    : "";
};

export function Throttle(fn, delay) {
  let timer = null;
  return function(...args) {
    clearTimeout(timer);
    timer = setTimeout(() => fn.apply(this, [...args]), delay);
  };
}
export function getOS() {
  let sUserAgent = navigator.userAgent;
  let isWin = navigator.platform == "Win32" || navigator.platform == "Windows";
  let isMac =
    navigator.platform == "Mac68K" ||
    navigator.platform == "MacPPC" ||
    navigator.platform == "Macintosh" ||
    navigator.platform == "MacIntel";
  if (isMac) return "Mac";
  let isUnix = navigator.platform == "X11" && !isWin && !isMac;
  if (isUnix) return "Unix";
  let isLinux = String(navigator.platform).indexOf("Linux") > -1;
  if (isLinux) return "Linux";
  if (isWin) {
    let isWin2K =
      sUserAgent.indexOf("Windows NT 5.0") > -1 ||
      sUserAgent.indexOf("Windows 2000") > -1;
    if (isWin2K) return "Win2000";
    let isWinXP =
      sUserAgent.indexOf("Windows NT 5.1") > -1 ||
      sUserAgent.indexOf("Windows XP") > -1;
    if (isWinXP) return "WinXP";
    let isWin2003 =
      sUserAgent.indexOf("Windows NT 5.2") > -1 ||
      sUserAgent.indexOf("Windows 2003") > -1;
    if (isWin2003) return "Win2003";
    let isWinVista =
      sUserAgent.indexOf("Windows NT 6.0") > -1 ||
      sUserAgent.indexOf("Windows Vista") > -1;
    if (isWinVista) return "WinVista";
    let isWin7 =
      sUserAgent.indexOf("Windows NT 6.1") > -1 ||
      sUserAgent.indexOf("Windows 7") > -1;
    if (isWin7) return "Win7";
    let isWin8 =
      sUserAgent.indexOf("windows NT 6.2") > -1 ||
      sUserAgent.indexOf("Windows 8") > -1;
    if (isWin8) return "Win8";
    let isWin10 =
      sUserAgent.indexOf("Windows NT 10") > -1 ||
      sUserAgent.indexOf("Windows 10") > -1;
    if (isWin10) return "Win10";
    let isWin11 =
      sUserAgent.indexOf("Windows NT 11") > -1 ||
      sUserAgent.indexOf("Windows 11") > -1;
    if (isWin11) return "Win11";
  }
  return "other";
}
export function getDownLoad(sys) {
  const latWin10_url =
    "https://jingyingbang-client.obs.myhuaweicloud.com/test/setup/net3.5/%E7%BB%8F%E8%90%A5%E5%B8%AE%E5%AE%A2%E6%88%B7%E7%AB%AF.exe";
  // win10及以上版本
  const gtWin10_url =
    "https://jingyingbang-client.obs.myhuaweicloud.com/test/setup/net4.5/%E7%BB%8F%E8%90%A5%E5%B8%AE%E5%AE%A2%E6%88%B7%E7%AB%AF.exe";
  const winEnum = {
    Win2000: latWin10_url,
    WinXP: latWin10_url,
    Win2003: latWin10_url,
    WinVista: latWin10_url,
    Win7: latWin10_url,
    Win8: latWin10_url,
    Win10: gtWin10_url,
    Win11: gtWin10_url,
    Mac: latWin10_url,
    Unix: latWin10_url,
    Linux: latWin10_url,
    other: latWin10_url
  }
 return winEnum[sys] || gtWin10_url
}
// export const Throttle = (fn, interval = 500) => {
//     let last
//     let timer
//     return function() {
//         const th = this
//         const args = arguments
//         const now = +new Date()
//         if (last && now - last < interval) {
//         clearTimeout(timer)
//         timer = setTimeout(function() {
//             last = now
//             fn.apply(th, args)
//         }, interval)
//         } else {
//         last = now
//         fn.apply(th, args)
//         }
//     }
// }
