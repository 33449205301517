import apiUser from "@/public/apiUser/apiUser";
import cookie from "@/storage/cookies";
export async function syncCookieToken() {
  let token = cookie.cookieRead("USER_ACCESS_TOKEN") || cookie.cookieRead("token");
  if(!token) return
  try {
    let res = await apiUser.findSsoUrlList();
    if (res.data.code === 200 && res.data.data) {
      let promiseList = [];
      res.data.data.forEach((r) => {
        promiseList.push(apiUser.setCookie(r));
      });
      await Promise.all(promiseList);
    }
  } catch (error) {
    console.log(error, "syncCookieToken");
  }
}
