import Home from "@/pages/Home";
import SearchResult from "@/pages/Search/index.vue";
import CompanyList from "@/pages/Search/page/companyList.vue";
import GoodsList from "@/pages/Search/page/goodsList.vue";
import TenderList from "@/pages/Search/page/tenderList.vue";
import GropbuyList from "@/pages/Search/page/gropbuyList.vue";
import LeaseList from "@/pages/Search/page/leaseList.vue";
import LabourList from "@/pages/Search/page/labourList.vue";
import LogisticsList from "@/pages/Search/page/logisticsList.vue";

import HelpCenter from "@/pages/HelpCenter/HelpCenter2.vue";
import Content from "@/pages/HelpCenter/Content.vue";
import serviceAgreement from "@/pages/bottom/serviceAgreement.vue";
import companyProfiles from "@/pages/bottom/companyProfiles.vue";
import companyDynamic from "@/pages/bottom/companyDynamic.vue";
import contactUs from "@/pages/bottom/contactUs.vue";
import dynamicDetail from "@/pages/bottom/dynamicDetail.vue";


// 发布
import Inquiry from "@/pages/Inquiry/index.vue";
import GoodsAsk from "@/pages/Inquiry/page/goodsAsk/index.vue";
import LeaseAsk from "@/pages/Inquiry/page/leaseAsk/index.vue";

// 推荐 成员 会员 关注企业 更多页面
import companyType from "@/pages/companyType/index";

let baseRoute = [
  {
    path: "/",
    name: "home",
    meta: {
      auth: false, // 是否登录状态下可访问
      keepAlive: false // 是否缓存页面
    },
    component: Home
  },
  {
    path: "/home",
    redirect: "/"
  },
  {
    path: "/SearchResult",
    name: "SearchResult",
    meta: {
      auth: false,
      keepAlive: false
    },
    redirect: "/SearchResult/companyList",
    component: SearchResult,
    children: [
      {
        path: "/SearchResult/companyList",
        name: "companyList",
        component: CompanyList,
        meta: {
          auth: false,
          keepAlive: false
        }
      },
      {
        path: "/SearchResult/goodsList",
        name: "goodsList",
        component: GoodsList,
        meta: {
          auth: false,
          keepAlive: false
        }
      },
      {
        path: "/SearchResult/tenderList",
        name: "tenderList",
        component: TenderList,
        meta: {
          auth: false,
          keepAlive: false
        }
      },
      {
        path: "/SearchResult/gropbuyList",
        name: "gropbuyList",
        component: GropbuyList,
        meta: {
          auth: false,
          keepAlive: false
        }
      },
      {
        path: "/SearchResult/leaseList",
        name: "leaseList",
        component: LeaseList,
        meta: {
          auth: false,
          keepAlive: false
        }
      },
      {
        path: "/SearchResult/labourList",
        name: "labourList",
        component: LabourList,
        meta: {
          auth: false,
          keepAlive: false
        }
      },
      {
        path: "/SearchResult/logisticsList",
        name: "logisticsList",
        component: LogisticsList,
        meta: {
          auth: false,
          keepAlive: false
        }
      }
    ]
  },
  // 发布
  {
    path: "/Inquiry",
    name: "Inquiry",
    meta: {
      auth: false,
      keepAlive: false
    },
    redirect: "/Inquiry/GoodsAsk",
    component: Inquiry,
    children: [
      {
        path: "/Inquiry/GoodsAsk",
        name: "GoodsAsk",
        component: GoodsAsk,
        meta: {
          auth: false,
          keepAlive: false
        }
      },
      {
        path: "/Inquiry/LeaseAsk",
        name: "LeaseAsk",
        component: LeaseAsk,
        meta: {
          auth: false,
          keepAlive: false
        }
      }
    ]
  },
  {
    path: "/helpCenter", // 帮助中心2
    name: "HelpCenter",
    component: HelpCenter,
    meta: {
      auth: false,
      keepAlive: true
    },
    children: [
      // 新手指南
      {
        path: "/content", // 注册账号
        name: "Content",
        component: Content,
        meta: {
          auth: false,
          keepAlive: false
        }
      }
    ]
  },
  {
    path: "/service-agreement", // 服务协议
    name: "service-agreement",
    component: serviceAgreement,
    meta: {
      auth: false,
      keepAlive: true
    }
  },
  {
    path: "/contact-us", // 联系我们
    name: "contact-us",
    component: contactUs,
    meta: {
      auth: false,
      keepAlive: true
    }
  },
  {
    path: "/companyProfiles", // 联系我们
    name: "CompanyProfiles",
    component: companyProfiles,
    meta: {
      auth: false,
      keepAlive: true
    }
  },
  {
    path: "/companyDynamic", // 企业动态
    name: "companyDynamic",
    component: companyDynamic,
    meta: {
      auth: false,
      keepAlive: true
    }
  },
  {
    path: "/dynamicDetail", // 企业动态详情
    name: "dynamicDetail",
    component: dynamicDetail,
    meta: {
      auth: false,
      keepAlive: true
    }
  },

  {
    path: "/companyType", // 首页企业更多
    name: "companyType",
    component: companyType,
    meta: {
      auth: false,
      keepAlive: true
    }
  },
  {
    path: '*',
    name: "home",
    meta: {
      auth: false, // 是否登录状态下可访问
      keepAlive: false // 是否缓存页面
    },
    component: Home
  }
];

export default baseRoute;
