var initNim;

import { Message } from "element-ui";
import store from "@/store/index.js";
// import SDK from "@/static/nimSDK/NIM_Web_SDK_v7.0.0.js";
if (process.client) {
  var SDK;
  SDK = require("@/static/nimSDK/NIM_Web_SDK_v7.0.0.js");

  function onConnect() {
    console.log("连接成功");
    if (process.client) {
      store().commit("updateNimState", true);
      window.nim && window.nim.getClientAntispamLexicon({
        done: function(error) {
          if (!error) {
          }
        }
      });
    }
  }

  //好友列表
  function onFriends(friends) {
    console.log('收到好友列表', friends);
    store().dispatch("setFriends", friends)
  }
  //在其他端对好友的操作
  function onSyncFriendAction(obj) {
    switch (obj.type) {
      case "addFriend":
        console.log(
          "你在其它端直接加了一个好友" + obj.account + ", 附言" + obj.ps
        );
        // store().dispatch("setFriends", obj.friend)
        break;
      case "applyFriend":
        console.log(
          "你在其它端申请加了一个好友" + obj.account + ", 附言" + obj.ps
        );
        break;
      case "passFriendApply":
        console.log(
          "你在其它端通过了一个好友申请" + obj.account + ", 附言" + obj.ps
        );
        // store().dispatch("setFriends", obj.friend)
        break;
      case "rejectFriendApply":
        console.log(
          "你在其它端拒绝了一个好友申请" + obj.account + ", 附言" + obj.ps
        );
        break;
      case "deleteFriend":
        console.log("你在其它端删了一个好友" + obj.account);
        store().dispatch("DELETE_FRIEND", obj.account);
        break;
      case "updateFriend":
        console.log("你在其它端更新了一个好友", obj.friend);
        // store().dispatch("setFriends", obj.friend)
        break;
    }
  }
  //群列表
  function onTeams(teams) {
    store().commit("SET_TEAMS", teams);
    store().dispatch("getTeamMembers", teams);
  }
  //群创建后的回调
  function onCreateTeam(team) {
    store().commit("SET_TEAMS", team);
    store().dispatch("getTeamMembers", team);
  }
  // 群状态更新
  function onUpdateTeam(team) {
    store().commit("SET_TEAMS", team);
  }
  function onDismissTeam(obj) {
    store().commit("DELETE_TEAM", {
      invalid: { teamId: obj.teamId }
    });
  }
  // 群组成员发生变化
  function onAddTeamMembers(obj) {
    store().commit("SET_TEAMS", obj.team);
    store().dispatch("getTeamMembers", obj.team);
  }
  function onRemoveTeamMembers(obj) {
    store().commit("SET_TEAMS", obj.team);
    store().dispatch("getTeamMembers", obj.team);
  }

  function onDisconnect(error) {
    console.log(error);
    if (error) {
      store().commit("updateNimState", false);
      switch (error.code) {
        // 账号或者密码错误
        case 302:
          Message.error("账号或者密码错误");
          break;
        // 重复登录, 已经在其它端登录了
        case 417:
          Message.error("重复登录, 已经在其它端登录了");
          break;
        // 被踢, 请提示错误后跳转到登录页面
        case "kicked":
          var map = {
            PC: "电脑版",
            Web: "网页版",
            Android: "手机版",
            iOS: "手机版",
            Mac: "电脑版",
            WindowsPhone: "手机版"
          };
          var str = error.from;
          Message.error(
            "你的帐号被" +
              (map[str] || "其他端") +
              "踢出下线，请确定帐号信息安全!"
          );
          break;
        default:
          break;
      }
    }
  }
  function onWillReconnect(obj) {
    // 此时说明 SDK 已经断开连接, 请开发者在界面上提示用户连接已断开, 而且正在重新建立连接
    // this.loadingText = "账号连接已断开, 正在重新建立连接.....";
    // this.loading = true;
    console.log(obj);
  }
  function onError(error) {
    console.log(error);
    console.log("错误信息" + error);
  }
  function onLoginPortsChange(loginPorts) {
    console.log("当前登录帐号在其它端的状态发生改变了", loginPorts);
  }
  //漫游消息
  function onRoamingMsgs(obj) {
    console.log("漫游消息", obj);
    pushMsg(obj.msgs);
  }
  // 离线消息
  function onOfflineMsgs(obj) {
    console.log("离线消息", obj);
    pushMsg(obj.msgs);
  }
  // 收到消息
  function onMsg(msg) {
    console.log("收到消息", msg.scene, msg.type, msg);
    pushMsg(msg);
  }
  //增加消息聊天
  function pushMsg(msgs) {
    store().commit("SET_MSGS", msgs);
  }
  //收到离线系统通知
  function onOfflineSysMsgs(sysMsgs) {
    console.log("收到离线系统通知", sysMsgs);
    pushSysMsgs(sysMsgs);
  }
  //漫游系统消息
  function onroamingsysmsgs(sysMsgs) {
    pushSysMsgs(sysMsgs);
  }
  //收到系统通知
  function onSysMsg(sysMsg) {
    console.log("收到系统通知", sysMsg);
    pushSysMsgs(sysMsg);
  }
  //更新系统消息
  function onUpdateSysMsg(sysMsg) {
    console.log("更新系统通知");
    pushSysMsgs(sysMsg);
  }
  function onSessions(session) {
    // session = window.nim.mergeSessions(store().state.session, session);
    console.log("会话获取@@@", session);
    // store().commit("SET_SESSION", session);
    store().dispatch("setSESSION", session);
  }
  //会话更新
  function onUpdateSession(session) {
    console.log(session);
    store().commit("UPDATE_SESSION", session);
  }
  //增加系统消息
  function pushSysMsgs(sysMsgs) {
    store().commit("SET_SYSMSGS", sysMsgs);
  }
  // 收到系统通知未读数
  function onSysMsgUnread(obj) {
    console.log("收到系统通知未读数", obj);
    store().commit("updatwSysMsgUnread", obj);
  }
  // 系统通知未读数更新了
  function onUpdateSysMsgUnread(obj) {
    console.log("系统通知未读数更新了", obj);
    store().commit("updatwSysMsgUnread", obj);
  }
  // 收到离线自定义系统通知
  function onOfflineCustomSysMsgs(sysMsgs) {
    console.log("收到离线自定义系统通知", sysMsgs);
    store().commit("updatwCustomSysMsgs", sysMsgs);
  }
  // 收到自定义系统通知
  function onCustomSysMsg(sysMsg) {
    console.log("收到自定义系统通知", sysMsg);
    store().commit("updatwCustomSysMsgs", sysMsg);
  }
  //同步完成
  function onSyncDone() {}
  var initNim = window.nim = (account, token) => {
    if (process.client) {
      if (window.nim) return;
      window.nim = SDK.NIM.getInstance({
        db: true,
        appKey: "06797c7320a41940acfa4f640de73559",
        account: account, //"201910091558292"
        token: token, //"bfe073d0a5fb96efa91e1a40c87217ce"
        syncSessionUnread: true,
        /*onconnect: onConnect,
        ondisconnect: onDisconnect,
        onwillreconnect: onWillReconnect,
        onerror: onError,
        onloginportschange: onLoginPortsChange,
        autoMarkRead: false,
        onsessions: onSessions,
        onupdatesession: onUpdateSession,
        // 消息
        onroamingmsgs: onRoamingMsgs,
        onofflinemsgs: onOfflineMsgs,
        onmsg: onMsg,
        // 系统通知
        syncRoamingMsgs: true,
        onofflinesysmsgs: onOfflineSysMsgs,
        onroamingsysmsgs: onroamingsysmsgs,
        onsysmsg: onSysMsg,
        onupdatesysmsg: onUpdateSysMsg,
        onsysmsgunread: onSysMsgUnread,
        onupdatesysmsgunread: onUpdateSysMsgUnread,
        onofflinecustomsysmsgs: onOfflineCustomSysMsgs,
        oncustomsysmsg: onCustomSysMsg,
        // 同步完成
        onsyncdone: onSyncDone*/

        onconnect: onConnect,
        ondisconnect: onDisconnect,
        onwillreconnect: onWillReconnect,
        onerror: onError,
        onloginportschange: onLoginPortsChange,
        autoMarkRead: false,
        onsessions: onSessions,
        onupdatesession: onUpdateSession,
        // 消息
        onroamingmsgs: onRoamingMsgs,
        onofflinemsgs: onOfflineMsgs,
        onmsg: onMsg,
        // 好友关系
        onfriends: onFriends,
        onsyncfriendaction: onSyncFriendAction,
        // 用户名片
        // onmyinfo: nimTool.onMyInfo,
        // onupdatemyinfo: nimTool.onUpdateMyInfo,
        // onusers: nimTool.onUsers,
        // onupdateuser: nimTool.onUpdateUser,
        // 群组
        onteams: onTeams,
        onsynccreateteam: onCreateTeam,
        onUpdateTeam: onUpdateTeam,
        onCreateTeam: onCreateTeam,
        onDismissTeam: onDismissTeam,
        onAddTeamMembers: onAddTeamMembers,
        onRemoveTeamMembers: onRemoveTeamMembers,
        // 系统通知
        syncRoamingMsgs: true,
        onofflinesysmsgs: onOfflineSysMsgs,
        onroamingsysmsgs: onroamingsysmsgs,
        onsysmsg: onSysMsg,
        onupdatesysmsg: onUpdateSysMsg,
        onsysmsgunread: onSysMsgUnread,
        onupdatesysmsgunread: onUpdateSysMsgUnread,
        onofflinecustomsysmsgs: onOfflineCustomSysMsgs,
        oncustomsysmsg: onCustomSysMsg,
        // 同步完成
        onsyncdone: onSyncDone,
      });
    }
  };
}
export default initNim;
