import Vue from 'vue'
import Router from 'vue-router'
import routes from './setting/routes'
import store from '../store';
import {Message} from "element-ui";
import apiHome from '@/api/apiHome'
import cookie from '@/storage/cookies.js'

// import contactUs from './modules/contactUs.js'
// import helpCenter from './modules/helpCenter.js'
// import home from './modules/home.js'
// import Inquiry from './modules/Inquiry.js'
// import search from './modules/search.js'

Vue.use(Router)
// const routes = []
// .concat(home)
// .concat(contactUs)
// .concat(helpCenter)
// .concat(Inquiry)
// .concat(search)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes:routes
});

// router.beforeEach((to, from, next) => {
//   if (to.path.includes('ServiceCenter') && !store.state.token) {
//
//   } else {
//     next();
//   }
// });
// memberUser
// router.beforeEach(async (to, from, next) => {
//   if (_hmt) {
//     if (to.path) {
//       _hmt.push(['_trackPageview', '/#' + to.fullPath]);
//     }
//   }
//   next();
// });

// router.afterEach((to, from, next) => {
//   window.scrollTo(0, 0)
// });

export default router
// module.exports = routes