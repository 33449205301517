import Vue from "vue";
import Vuex from "vuex";
import cookie from "@/storage/cookies.js";
import apiUser from "@/public/apiUser/apiUser";
import apiAuthentication from "@/api/apiAuthentication.js";
import apiAuth from "@/api/apiAuth.js";
import {
  Message
} from "element-ui";
import router from "../router/index";
import apiIm from '@/api/apiIminfo'
// import SDK from '@/static/nimSDK/NIM_Web_SDK_v7.0.0.js'
import * as nimTool from "@/utils/nimTool.js";
import initNim from "@/utils/initNim";
import initNim2 from "@/utils/nimTool";
import getFriendName from "@/utils/nimTool";
import {
  apiHome
} from "../api/apiHome";
import { logout } from '@/api/apiLogin'
// import IM from "./modules/im.js";
var env = require("../../env");
import axios from 'axios'
// VUE_APP_APIUSER
// Vue.use(Vuex);
//使用递归,追加到数组里面去.
function append(pushArr, dataArr) {
  for (let i = 0, len = dataArr.length; i < len; i++) {
    pushArr.push(dataArr[i].url);
    if (dataArr[i].children && dataArr[i].children.length) {
      append(pushArr, dataArr[i].children);
    }
  }
}


const state = () => ({
  //全局所有地区
  globalAllArea: [],
  counter: 0,
  isOpen: false,
  toUser: "",
  authUser: {},
  session: [], // 消息会话
  friends: [], // 好友列表
  teams: [], // 群组列表
  customSysMsgs: [], // 自定义系统消息
  msgs: {}, // msgs：消息对象集合
  imUserName: '',
  teamMember: {},
  storeInformation: {},
  loginType: undefined,
  token: "",
  user: {},
  sysMsgUnread: {},
  areaList: [], // 所有地区
  area: {}, // 地区选择
  upload: `${env[process.env.environment].VUE_APP_APIUSER}/file/add`,
  uploadMess: `${env[process.env.environment].VUE_APP_APIUSER
    }/file/recognition`,
  driverInfo: null,
  storeType: null,
  listMune: [
    "钢材",
    "沥青",
    "水泥",
    "煤",
    "商砼",
    "砂石料/石材",
    "砖/水泥制品",
    "油品"
  ],
  refreshList: true,
  imgUrl: "https://shigongbang.obs.cn-east-3.myhuaweicloud.com/",
  allJuri: [],
  position: {
    lng: 0,
    lat: 0
  },
  CompanyOrganization: [], // 企业列表 用户身份
  showImChat: false,
  tenderType: {},
  searchKeyword: '',
  IndexMenuList: [],
  isHasIpAddress: false,
  isVipCard: false,
  relevance: false, // 当前身份是否关联企业
  relevanceAdmin:{}, //获取当前用户的关联企业以及是否为此企业超管或管理员
  teamAuthStatus: '' // 是否认证过团队 status:2即代表有认证，否则没有
});
const mutations = {
  SET_SEARCH_KEY(state, val) {
    state.searchKeyword = val
  },
  SEND_MESSAGE(state, showImChat = true) { // 新版IM交流
    console.log('新版IM交流', showImChat)
    state.showImChat = showImChat
  },
  SET_GLOBALALLAREA(state, arr) {
    state.globalAllArea = arr
  },
  SET_LOGINTYPE(state, isLogin) {
    state.loginType = isLogin;
  },
  SET_TOKEN(state, token) {
    if (!token) {
      window.noticeWs && window.noticeWs.close()
      window.noticeWs = null
    } else if (token && !window.noticeWs) {
      let url = env[process.env.environment].VUE_APP_NOTIFYWS
      window.noticeWs = new zjNotifySocket({ url, token })
    }
    state.token = token;
  },
  SET_USER(state, user) {
    state.user = user;
    this.dispatch('setUserCompany')
    this.dispatch('setUserMenu')
    this.dispatch('setUserRelevance')
    this.dispatch('setUserRelevanceAdmin')
    this.dispatch('setCurrentUserAuthTeam')
  },
  SET_AUTHUSER(state, auth) {
    state.authUser = auth
  },
  // 设置消息列表
  SET_MSGS(state, msgs) {

    if (process.client) {
      console.log(state, msgs, "@@@@@@@@@@@@@");

      if (!Array.isArray(msgs)) {
        msgs = [msgs];
      }
      if (!msgs[0]) return;
      var sessionId = msgs[0].sessionId;
      let value = window.nim.mergeMsgs(state.msgs[sessionId], msgs);
      state.msgs[sessionId] = value;
      state.msgs = Object.assign({}, state.msgs);
    }
  },
  // 删除消息
  DELMSYS(state, obj) {
    state.msgs[obj.msg.sessionId].forEach((item, index) => {
      if (item.idServer == obj.msg.idServer) {
        state.msgs[obj.msg.sessionId].splice(index, 1, obj.data);
      }
    });
  },
  // 设置系统消息列表
  SET_SYSMSGS(state, sysMsgs) {

    if (process.client) {
      if (
        !Array.isArray(sysMsgs) &&
        sysMsgs.type == "deleteMsg" &&
        state.msgs[sysMsgs.msg.sessionId]
      ) {
        state.msgs[sysMsgs.msg.sessionId].forEach((item, index) => {
          if (item.idServer == sysMsgs.deletedIdServer) {
            state.msgs[sysMsgs.msg.sessionId].splice(index, 1);
            nimTool.sendTipMsg(sysMsgs.msg, state);
          }
        });
      }
      let sysMagList = Array.isArray(sysMsgs) ? sysMsgs : [sysMsgs];
      let delemsg = sysMagList.filter(item => {
        return !item.type && item.type == "deleteMsg";
      });
      if (delemsg.length > 0) {
        window.nim.markSysMsgRead({
          sysMsgs: delemsg,
          done: (err, obj) => { }
        });
        let ids = delemsg.map(item => item.idServer);
        window.nim.deleteLocalSysMsg({
          idServer: ids,
          done: (err, obj) => { }
        });
      }
      state.sysMsgs = window.nim.mergeSysMsgs(state.sysMsgs, sysMsgs);
    }
  },
  // 更新未读数
  updatwSysMsgUnread(state, obj) {
    state.sysMsgUnread = obj;
  },
  // 更新系统消息
  updatwCustomSysMsgs(state, obj) {
    let value = state.customSysMsgs;
    value.push(obj);
    state.customSysMsgs = value;
  },
  // 会话消息
  SET_SESSION(state, obj) {
    if (process.client) {

      state.session = window.nim.mergeSessions(state.session, obj);
    }
  },
  // 更新会话
  UPDATE_SESSION(state, obj) {
    let sortIndex = "";
    state.session.forEach((item, index) => {
      if (item.id == obj.id) {
        sortIndex = index;
      }
    });
    if (sortIndex !== "") {
      state.session.splice(sortIndex, 1, obj);
    } else {
      state.session.push(obj);
      console.log(state.session);
    }
  },
  DELETE_SESSION(state, id) {
    state.session.forEach((item, index) => {
      if (item.id == id) {
        state.session.splice(index, 1);
      }
    });
  },
  // 更新nim链接状态
  updateNimState(state, flg) {
    state.nimState = flg;
  },
  // 设置toUser
  SETTOUSER(state, id) {
    if (process.client) {
      window.nim && window.nim.setCurrSession(id);
      state.toUser = id;
    }
  },
  // 设置是否展开
  SET_ISOPEN(state, flg) {
    // if (flg) {
    //   state.toUser = state.session[0] ? state.session[0].to : "";
    // } else {
    //   state.toUser = "";
    // }
    state.isOpen = flg;
  },
  // 好友列表
  SET_FRIENDS(state, friends) {
    state.friends = friends;
  },
  // 删除好友
  DELETE_FRIEND(state, account) {
    if (process.client) {
      let list = window.nim.cutFriendsByAccounts(state.friends, account);
      state.friends = Object.assign({}, list);
    }
  },
  DELETE_TEAM(state, teams) {
    let list = window.nim.mergeTeams(state.teams, teams)
    list = window.nim.cutTeams(state.teams, teams.invalid)
    state.teams = list
  },
  // 设置群组列表
  SET_TEAMS(state, teams) {
    if (!Array.isArray(teams)) {
      teams = [teams]
    }
    teams = teams.filter(item => !!item)
    teams.forEach(team => {
      if (team.validToCurrentUser === undefined) {
        team.validToCurrentUser = true
      }
    })
    let list = window.nim.mergeTeams(state.teams, teams)
    list = window.nim.cutTeams(list, teams.invalid)
    state.teams = list
  },
  SET_IMUSERNAME(state, name) {
    state.imUserName = name
  },
  async LOGIN_OUT(state) {
    if (process.client) {
      try {
        // await apiHome.delRedisUserToken()
        // console.log('logout', logout)
        await logout()
      } catch (error) {
        console.log('errro', error)
      }
      cookie.cookieClear("token");
      cookie.cookieClear('USER_ACCESS_TOKEN')
      cookie.cookieClear("userId");
      cookie.cookieClear("isAuthDriver");
      localStorage.removeItem("DriverInfo");
      cookie.cookieClear("userInfo");
      cookie.cookieClear("isLogin");
      state.toUser = "";
      state.session = [];
      state.friends = [];
      state.teams = [];
      state.token = null;
      state.user = {};
      state.loginType = false;
      state.isOpen = false;
      state.teamMember = {};
      // window.nim &&
      //   window.nim.destroy({
      //     done: err => {}
      //   });
      this.dispatch('setUserMenu')
      window.nim = null;
      window.noticeWs && window.noticeWs.close()
      window.noticeWs = null
      // router.push("/");
    }
  },
  LOGIN_OUT_SERVER(state) {
    if (process.client) {
      cookie.cookieClear("token");
      cookie.cookieClear('USER_ACCESS_TOKEN')
      cookie.cookieClear("isAuthDriver");
      localStorage.removeItem("DriverInfo");
      cookie.cookieClear("userInfo");
      cookie.cookieClear("isLogin");
      cookie.cookieClear("userId");
      state.toUser = "";
      state.session = [];
      state.friends = [];
      state.teams = [];
      state.token = null;
      state.user = {};
      state.loginType = false;
      state.isOpen = false;
      state.teamMember = {};
      state.teamAuthStatus = ''
      state.relevanceAdmin = {}
      state.relevance = false
      window.nim = null;
      window.noticeWs && window.noticeWs.close()
      window.noticeWs = null

      // router.push("/");
    }
  },
  // 这里
  SET_AREA(state, item) {
    state.area = item;
  },
  SET_AREALIST(state, areaList) {
    state.areaList = areaList;
  },
  SET_DRIVERINFO(state, obj) {
    state.driverInfo = obj;
  },
  SET_STORE_TYPE(state, type) {
    state.storeType = type;
  },
  SET_ALL_JURI(state, type) {
    state.allJuri = type;
  },
  UPDATAREFRESHLIST(state) {
    state.refreshList = !state.refreshList
  },
  updateStoreInformation(state, data) {
    state.storeInformation = data;
  },
  SET_TEAMMENBER(state, obj) {
    let json = Object.assign({}, state.teamMember);
    json[obj.teamId] = obj.menber;
    state.teamMember = json;
  },
  SET_POSITION(state, position) { //设置当前所在地的经纬度
    state.position = position
  },
  SET_USER_COMPANY(state, payload) {
    state.CompanyOrganization = payload
  },
  SET_TENDER_ENUM(state, payload) {
    state.tenderType = payload
  },
  SET_INDEXMENU(state, list) {
    state.IndexMenuList = []
    state.IndexMenuList = list
  },
  SET_CURRENTIPADDRESS(state, isHasIpAddress) {
    state.isHasIpAddress = isHasIpAddress
  },
  SET_VIP_CARD(state, isVip) {
    state.isVipCard = isVip
  },
  SET_RELEVANCE(state, relevance){
    state.relevance = relevance
  },
  SET_RELEVANCE_ADMIN(state, relevanceAdmin){
    state.relevanceAdmin = relevanceAdmin
  },
  SET_CURRENTUSER_TEAM(state,teamAuthStatus){
    state.teamAuthStatus = teamAuthStatus
  }
};
const actions = {
  nuxtServerInit({
    commit
  }, {
    req
  }) { },
  async setSESSION({
    commit
  }, obj) {
    var obj1 = await window.nim.mergeSessions(this.state.session, obj);
    commit("SET_SESSION", obj1);
  },
  // 招标类型集合
  async setTenderType({ commit, state }) {
    let res = await apiHome.getAdvancMenu();
    const { result, code, msg } = res.data;
    if (+code === 0) {
      const list = Object.fromEntries(result.map(x => ([x.no, x.name])))
      commit('SET_TENDER_ENUM', list)
    }
  },
  async setUserCompany({ commit, state }) {
    let comRes = await apiAuth.getUserCompany()
    let comList = comRes.data.data || []
    let vipComRes = await apiAuth.getUserCurrentServiceStatus(comList.concat({
      no: state.user.no,
      type: '0'
    }))
    let vipComList = vipComRes.data.data
    comList = comList.map(item=>{
      let isVip = false
      if(vipComList[item.no]){
        isVip = true 
      } 
      return {
        ...item,
        isVip
      }
    })
    console.log('comList', comList)
    commit('SET_USER_COMPANY', comList)
  },
  async setUser({
    commit
  }) {
    let token = cookie.cookieRead("token");
    let res = await apiUser.getUserInfo();
    // console.log('userinfo', res.data.data, res.data.data.companyNo)
    try {
      let companyNo = res.data.data.companyNo || res.data.data.teamNo || res.data.data.no
      console.log('companyNo', companyNo)
      let data = {
        companyNo: companyNo,
        siteType: res.data.data.type
      }
      let vipres = await apiUser.checkWhetherOpen(data)
      let isVip = vipres.data.data
      commit("SET_VIP_CARD", isVip)
    } catch (error) {
      commit("SET_VIP_CARD", false)
    }
    let userInfo = res.data && res.data.data || {};
    commit("SET_LOGINTYPE", true);
    commit("SET_TOKEN", token);
    commit("SET_USER", userInfo);
    cookie.cookieSet('userId', userInfo.no || '')
    let resNew = await apiUser.getJurisdiction();

    if (resNew.data.result) {
      commit('SET_AUTHUSER', resNew.data.result)
    } else {
    }
  },
  // 检查当前身份是否关联企业
  async setUserRelevance({
    commit
  }) {
    try {
      let res = await apiUser.currentUserRelevanceCompany()
      commit("SET_RELEVANCE", res.data.data)
    } catch (error) {

    }
  },
  //获取当前用户的关联企业以及是否为此企业超管或管理员 
  async setUserRelevanceAdmin({
    commit
  }) {
    try {
      let res = await apiUser.getCurrentUserRelevanceCompanyInfo()
      commit("SET_RELEVANCE_ADMIN", res.data.data)
    } catch (error) {

    }
  },
  //获取当前用户是否认证过团队 
  async setCurrentUserAuthTeam({
    commit
  }) {
    try {
      let res = await apiUser.currentUserAuthTeam()
      console.log('currentUserAuthTeamcurrentUserAuthTeamcurrentUserAuthTeam', res.data.data)
      commit("SET_CURRENTUSER_TEAM", res.data.data)
    } catch (error) {

    }
  },
  async getTeamMembers({
    commit,
    state
  }, item) {
    let list = Array.isArray(item) ? item : [item]
    let member = {}
    list.forEach(item => {
      console.log('teamMember item:', item)
      let id = item.teamId
      window.nim.getTeamMembers({
        teamId: id,
        done: async (error, obj) => {
          if (!error && obj) {
            let owner = obj.members.filter(item => (item.type == 'owner'))
            let ids = obj.members.filter(item => (item.account)).map(item => (item.account))
            let res = await apiIm.getUserName({
              accountList: ids,
              teamId: id
            })
            if (res.data.code == 200) {
              let sortList = []
              res.data.data.forEach(item => {
                if (item.imAccount == owner[0].account) {
                  sortList.unshift(item)
                } else {
                  sortList.push(item)
                }
              })
              let teamMenber = {}
              teamMenber.teamId = '' + obj.teamId
              teamMenber.menber = sortList
              commit('SET_TEAMMENBER', teamMenber)
            }
          }
        }
      })
    })
  },
  async setFriends({
    commit,
    state
  }, friends) {
    let list = await nimTool.getFriendName(friends)
    if (!list[0]) {
      return
    }
    list = window.nim.mergeFriends(state.friends, list)
    list = window.nim.cutFriends(list, list.invalid)
    commit('SET_FRIENDS', list)
  },
  async setStoreInformation({
    commit
  }) {
    let getBusinessData = await apiAuthentication.getStoreInformation();
    let data = getBusinessData.data.data;
    if (data && data.image !== "") {
      data.image = data.image.split(",");
    }
    commit("updateStoreInformation", data);
  },
  async setUserMenu({ state, commit }) {
    let menu = await apiHome.getMenuList();
    commit('SET_INDEXMENU', menu.data.data)
  }
};
// const strict = env[process.env.environment].NODE_ENV !== "production";
let store;
const initStore = () => {
  return (
    store ||
    (store = new Vuex.Store({
      // 存放公用数据
      state,
      // 异步操作要通过actions，否则通过cimmit直接操作mutations
      actions,
      // 同步放数据
      mutations,
      // strict
    }))
  );
};

export default initStore;
// export default () => {
//   return new Vuex.Store({
//     state: {
//       counter: 0,
//       isOpen: false,
//       toUser: "",
//       session: [], // 消息会话
//       friends: [], // 好友列表
//       teams: [], // 群组列表
//       msgs: {}, // msgs：消息对象集合
//       teamMember: {},
//       storeInformation: {},
//       loginType: false,
//       token: "",
//       user: {},
//       sysMsgUnread: {},
//       areaList: [], // 所有地区
//       area: {}, // 地区选择
//       upload: `${env[process.env.environment].VUE_APP_APIUSER}/file/add`,
//       uploadMess: `${
//         env[process.env.environment].VUE_APP_APIUSER
//       }/file/recognition`,
//       driverInfo: null,
//       storeType: null,
//       listMune: [
//         "钢材",
//         "沥青",
//         "水泥",
//         "煤",
//         "商砼",
//         "砂石料/石材",
//         "砖/水泥制品",
//         "油品"
//       ],
//       imgUrl: "https://shigongbang.obs.cn-east-3.myhuaweicloud.com/",
//       allJuri: []
//     },
//     mutations: {
//       SET_LOGINTYPE(state, isLogin) {
//         state.loginType = isLogin;
//       },
//       SET_TOKEN(state, token) {
//         state.token = token;
//       },
//       SET_USER(state, user) {
//         state.user = user;
//         if (process.client) {
//           if (user && user.imAccount && user.imToken) {
//             initNim(user.imAccount, user.imToken);
//           }
//         }

//         // if (user.imAccount && user.imToken) {
//         //   this.dispatch('initNim', user)
//         // }
//       },
//       // 设置消息列表
//       SET_MSGS(state, msgs) {
//
//         if (process.client) {
//           console.log(state, msgs, "@@@@@@@@@@@@@");

//           if (!Array.isArray(msgs)) {
//             msgs = [msgs];
//           }
//           if (!msgs[0]) return;
//           var sessionId = msgs[0].sessionId;
//           let value = window.nim.mergeMsgs(state.msgs[sessionId], msgs);
//           state.msgs[sessionId] = value;
//           state.msgs = Object.assign({}, state.msgs);
//         }
//       },
//       // 删除消息
//       DELMSYS(state, obj) {
//         state.msgs[obj.msg.sessionId].forEach((item, index) => {
//           if (item.idServer == obj.msg.idServer) {
//             state.msgs[obj.msg.sessionId].splice(index, 1, obj.data);
//           }
//         });
//       },
//       // 设置系统消息列表
//       SET_SYSMSGS(state, sysMsgs) {
//
//         if (process.client) {
//           if (
//             !Array.isArray(sysMsgs) &&
//             sysMsgs.type == "deleteMsg" &&
//             state.msgs[sysMsgs.msg.sessionId]
//           ) {
//             state.msgs[sysMsgs.msg.sessionId].forEach((item, index) => {
//               if (item.idServer == sysMsgs.deletedIdServer) {
//                 state.msgs[sysMsgs.msg.sessionId].splice(index, 1);
//                 nimTool.sendTipMsg(sysMsgs.msg, state);
//               }
//             });
//           }
//           let sysMagList = Array.isArray(sysMsgs) ? sysMsgs : [sysMsgs];
//           let delemsg = sysMagList.filter(item => {
//             return !item.type && item.type == "deleteMsg";
//           });
//           if (delemsg.length > 0) {
//             window.nim.markSysMsgRead({
//               sysMsgs: delemsg,
//               done: (err, obj) => {}
//             });
//             let ids = delemsg.map(item => item.idServer);
//             window.nim.deleteLocalSysMsg({
//               idServer: ids,
//               done: (err, obj) => {}
//             });
//           }
//           state.sysMsgs = window.nim.mergeSysMsgs(state.sysMsgs, sysMsgs);
//         }
//       },
//       // 更新未读数
//       updatwSysMsgUnread(state, obj) {
//         state.sysMsgUnread = obj;
//       },
//       // 更新系统消息
//       updatwCustomSysMsgs(state, obj) {
//         let value = state.customSysMsgs;
//         value.push(obj);
//         state.customSysMsgs = value;
//       },
//       // 会话消息
//       SET_SESSION(state, obj) {
//         if (process.client) {
//
//           state.session =window.nim.mergeSessions(state.session, obj);
//         }
//       },
//       // 更新会话
//       UPDATE_SESSION(state, obj) {
//         let sortIndex = "";
//         state.session.forEach((item, index) => {
//           if (item.id == obj.id) {
//             sortIndex = index;
//           }
//         });
//         if (sortIndex !== "") {
//           state.session.splice(sortIndex, 1, obj);
//         } else {
//           state.session.push(obj);
//           console.log(state.session);
//         }
//       },
//       DELETE_SESSION(state, id) {
//         state.session.forEach((item, index) => {
//           if (item.id == id) {
//             state.session.splice(index, 1);
//           }
//         });
//       },
//       // 更新nim链接状态
//       updateNimState(state, flg) {
//         state.nimState = flg;
//       },
//       // 设置toUser
//       SETTOUSER(state, id) {
//         if (process.client) {
//           window.nim && window.nim.setCurrSession(id);
//           state.toUser = id;
//         }
//       },
//       // 设置是否展开
//       SET_ISOPEN(state, flg) {
//         if (flg) {
//           state.toUser = state.session[0] ? state.session[0].to : "";
//         } else {
//           state.toUser = "";
//         }
//         state.isOpen = flg;
//       },
//       // 好友列表
//       SET_FRIENDS(state, friends) {
//         state.friends = friends;
//       },
//       // 删除好友
//       DELETE_FRIEND(state, account) {
//         if (process.client) {
//           let list = window.nim.cutFriendsByAccounts(state.friends, account);
//           state.friends = Object.assign({}, list);
//         }
//       },
//       LOGIN_OUT(state) {
//         if (process.client) {
//           cookie.cookieClear("token");
//           cookie.cookieClear("isAuthDriver");
//           localStorage.removeItem("DriverInfo");
//           localStorage.removeItem("userInfo");
//           state.toUser = "";
//           state.session = [];
//           state.friends = [];
//           state.teams = [];
//           state.token = null;
//           state.user = null;
//           state.loginType = false;
//           state.isOpen = false;
//           state.teamMember = {};
//           window.nim &&
//             window.nim.destroy({
//               done: err => {}
//             });
//           window.nim = null;
//           router.push("/");
//         }
//       },
//       // 这里
//       SET_AREA(state, item) {
//         state.area = item;
//       },
//       SET_AREALIST(state, areaList) {
//         state.areaList = areaList;
//       },
//       SET_DRIVERINFO(state, obj) {
//         state.driverInfo = obj;
//       },
//       SET_STORE_TYPE(state, type) {
//         state.storeType = type;
//       },
//       SET_ALL_JURI(state, type) {
//         state.allJuri = type;
//       },
//       updateStoreInformation(state, data) {
//         state.storeInformation = data;
//       },
//       SET_TEAMMENBER(state, obj) {
//         let json = Object.assign({}, state.teamMember);
//         json[obj.teamId] = obj.menber;
//         state.teamMember = json;
//       }
//     },
//     actions: {
//        nuxtServerInit({ commit}, { req }) {
//        },
//       async setSESSION({ commit }, obj) {
//         var obj1 = await window.nim.mergeSessions(this.state.session, obj);
//         commit("SET_SESSION", obj1);
//       },
//       async setUser({ commit }) {
//         let token = cookie.cookieRead("token");
//         let res = await apiUser.getUserInfo();
//         let user = res.data.data;
//         commit("SET_LOGINTYPE", true);
//         commit("SET_TOKEN", token);
//         commit("SET_USER", user);

//         let resNew = await apiUser.getJurisdiction();
//         if (resNew.data.result && resNew.data.result.routes.length) {
//           let arr = resNew.data.result.routes;
//           let pushArr = [];
//           await append(pushArr, arr);
//           // commit("SET_ALL_JURI", pushArr);
//         } else {
//           // commit("SET_ALL_JURI", []);
//         }
//       },
//       async setStoreInformation({ commit }) {
//         let getBusinessData = await apiAuthentication.getStoreInformation();
//         let data = getBusinessData.data.data;
//         if (data && data.image !== "") {
//           data.image = data.image.split(",");
//         }
//         commit("updateStoreInformation", data);
//       }
//     },
//     strict: env[process.env.environment].NODE_ENV !== "production"
//   });
// };
