import Vue from "vue";
import Router from "vue-router";
import baseRoute from "@/router/setting/routes";
// spa模式下 路由懒加载 暂未实现该模式下的路由加载
// import routes from "@/router/index";
import cookie from "@/storage/cookies";
import Home from "@/pages/Home";
import store from "@/store/index";
import { apiHome } from "@/api/apiHome";
const routerPush = Router.prototype.push;
const routerReplacce = Router.prototype.replace;
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error);
};
Router.prototype.replace = function push(location) {
  return routerReplacce.call(this, location).catch(error => error);
};
Vue.use(Router);
export function createRouter() {
  let router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: baseRoute
  });
  if (process.client) {
    router.beforeEach((to, from, next) => {
      let token = cookie.cookieRead("token")
      if (!token) {
        apiHome
          .memberUser()
          .then(Sxyret => {
            try {
              if (Sxyret.data.code == 0) {
                cookie.cookieSet("token", Sxyret.data.msg);
                store()
                  .dispatch("setUser")
                  .then(res => {
                    next(to.fullPath);
                  });
                next(to.fullPath);
              } else {
                next(to.fullPath);
              }
            } catch (error) {
              next(to.fullPath);
            }
          })
          .catch(err => {
            next();
          });
      } else {
        next();
      }
      // if (_hmt) {
      //   if (to.path) {
      //     _hmt.push(["_trackPageview", "/#" + to.fullPath]);
      //   }
      // }
      next();
    });
    router.afterEach((to, from, next) => {
      window.scrollTo(0, 0);
    });
  }

  return router;
}

