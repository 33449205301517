import Toast from "@/library/toast/Index.js";
import ConfirmPlaugin1 from "@/library/ConfirmPlaugin/ConfirmPlaugin1/Index.js";
import ConfirmPlaugin2 from "@/library/ConfirmPlaugin/ConfirmPlaugin2/Index.js";
import ConfirmPlaugin3 from "@/library/ConfirmPlaugin/ConfirmPlaugin3/Index.vue";
import ConfirmPlaugin4 from "@/library/ConfirmPlaugin/ConfirmPlaugin4/Index.vue";

import Button1 from "@/library/Button/Button1/Index.vue";
import Button2 from "@/library/Button/Button2/Index.vue";
import Button3 from "@/library/Button/Button3/Index.vue";
import Button4 from "@/library/Button/Button4/Index.vue";
import Button5 from "@/library/Button/Button5/Index.vue";
import Button6 from "@/library/Button/Button6/Index.vue";
import Button7 from "@/library/Button/Button7/Index.vue";

import Title1 from "@/library/Title/Title1/Index.vue";

import NoMessage0 from "@/library/NoMessage/NoMessage0/Index.vue";
import NoMessage1 from "@/library/NoMessage/NoMessage1/Index.vue";
import NoMessage2 from "@/library/NoMessage/NoMessage2/Index.vue";

import Tr1 from "@/library/Table/Table1/Tr1/Index.vue";
import Td1 from "@/library/Table/Table1/Td1/Index.vue";

import Tr2 from "@/library/Table/Table2/Tr2/Index.vue";
import Td2 from "@/library/Table/Table2/Td2/Index.vue";

import Tr3 from "@/library/Table/Table3/Tr3/Index.vue";
import Td3 from "@/library/Table/Table3/Td3/Index.vue";

import Tr4 from "@/library/Table/Table4/Tr4/Index.vue";
import Td4 from "@/library/Table/Table4/Td4/Index.vue";

import TabBox1 from "@/library/Tab/Tab1/TabBox1/Index.vue";
import TabInner1 from "@/library/Tab/Tab1/TabInner1/Index.vue";

import TabBox2 from "@/library/Tab/Tab2/TabBox2/Index.vue";
import TabInner2 from "@/library/Tab/Tab2/TabInner2/Index.vue";

// import RegionCascader from '@/library/AreaChange/regionCascader/regionCascader.vue'

import Login from "@/library/Login/Login/Index.js";
import AuthUser from "@/library/AuthUser/index.js";

import BigImage1 from "@/library/BigImg/BigImage1/Index.js";

// import Input1 from '@/library/Input/Input1/Index.vue'
const comment = {
  install: function(Vue) {
    Vue.prototype.$_toast = Toast;
    Vue.prototype.$_popout1 = ConfirmPlaugin1;
    Vue.prototype.$_popout2 = ConfirmPlaugin2;

    Vue.prototype.$_bigImage1 = BigImage1;

    Vue.prototype.$_login = Login;
    Vue.prototype.$_authUser = AuthUser;

    Vue.component("Popout3", ConfirmPlaugin3);
    Vue.component("Popout4", ConfirmPlaugin4);
    // Vue.component(HelloWorld.name, HelloWorld)
    Vue.component(Button1.name, Button1);
    Vue.component(Button2.name, Button2);
    Vue.component(Button3.name, Button3);
    Vue.component(Button4.name, Button4);
    Vue.component(Button5.name, Button5);
    Vue.component(Button6.name, Button6);
    Vue.component(Button7.name, Button7);

    Vue.component(Title1.name, Title1);

    Vue.component(NoMessage0.name, NoMessage0);
    Vue.component(NoMessage1.name, NoMessage1);
    Vue.component(NoMessage2.name, NoMessage2);
    // Vue.component(Input1.name, Input1)

    Vue.component(Tr1.name, Tr1);
    Vue.component(Td1.name, Td1);
    Vue.component(Tr2.name, Tr2);
    Vue.component(Td2.name, Td2);
    Vue.component(Tr3.name, Tr3);
    Vue.component(Td3.name, Td3);
    Vue.component(Tr4.name, Tr4);
    Vue.component(Td4.name, Td4);

    Vue.component(TabBox1.name, TabBox1);
    Vue.component(TabInner1.name, TabInner1);
    Vue.component(TabBox2.name, TabBox2);
    Vue.component(TabInner2.name, TabInner2);

    // Vue.component(RegionCascader.name, RegionCascader)
  }
};
// 这里的判断很重要
if (process.client) {
  if (typeof window !== "undefined" && window.Vue) {
    window.Vue.use(comment);
  }
}
export default comment;
// npm login
// tickaway
// npm run build-bundle
// npm publish
