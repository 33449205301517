var localStorage = {};
if (process.client) {
  const storage = window.localStorage;

  function set(key, value) {
    storage.setItem(key, value);
  }

  function setMulti(datas) {
    datas.forEach(data => set(data.key, data.value));
  }

  function read(key) {
    return storage.getItem(key);
  }

  function readMulti(keys) {
    return keys.map(key => read(key));
  }

  function clear(key, clearAll = false) {
    clearAll ? storage.clear() : storage.removeItem(key);
  }

  function clearMulti(keys) {
    keys.forEach(key => clear(key));
  }

   localStorage = {
    set,
    setMulti,
    read,
    readMulti,
    clear,
    clearMulti
  };
}
export default localStorage;
