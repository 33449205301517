// sessionStorage
// localStorage
var sessionStorage = {};
if (process.client) {
 const storage = window.sessionStorage


function save (key, value) {
  storage.setItem(key, value)
}

function read (key) {
  return storage.getItem(key)
}

function clear (key, clearAll = false) {
  if (clearAll) {
    storage.clear()
  } else {
    storage.removeItem(key)
  }
}

 sessionStorage = {
  save,
  read,
  clear
}
}
export default sessionStorage
